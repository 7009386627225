/* eslint-disable func-names */
import debounce from 'lodash/debounce';
import { getCSRFToken } from '~/shared/utils/request';

export const ProjectCostBreakdown = {
  init() {
    // prevent initializing multiple times
    if (window.projectCostBreakdownInitialized) return;

    this.fetchAbortController = null;
    window.projectCostBreakdownInitialized = true;

    this.bindListeners();
  },

  getClosestBreakdownContainer(el) {
    return el.closest('[data-project-breakdown]');
  },

  getMilestoneSelect(breakdownContainer) {
    return breakdownContainer?.querySelector('[data-project-breakdown-milestone-select]');
  },

  bindListeners() {
    // Filter by mode (Client or Designer) when clicking on the mode tabs
    document.addEventListener('click', (e) => {
      const targetMode = e.target.closest('[data-project-breakdown-set-mode]')?.getAttribute('data-project-breakdown-set-mode');
      if (!targetMode) return;

      this.getClosestBreakdownContainer(e.target)?.setAttribute('data-project-breakdown-filter-mode', targetMode);
    });

    // Filter by milestone name when changing the milestone select value
    document.addEventListener('change', (e) => {
      const projectBreakdownContainer = this.getClosestBreakdownContainer(e.target);
      const milestoneSelect = this.getMilestoneSelect(projectBreakdownContainer);
      const isMilestoneSelect = milestoneSelect === e.target;
      if (!isMilestoneSelect) return;

      projectBreakdownContainer?.querySelectorAll('[data-project-breakdown-milestone-identifier]').forEach((milestone) => {
        milestone.toggleAttribute('hidden', milestone.getAttribute('data-project-breakdown-milestone-identifier') !== milestoneSelect.value);
      });
    });
  },

  /**
   * Fetches project cost breakdown content and updates the project breakdown container.
   *
   * @param {Object} options - Options object
   * @param {Element} options.form - Form element that contains the project breakdown container
   * @param {String} options.path - Path to fetch the project breakdown content
   * @param {Array} options.previewMilestones - Array of milestones (`step`, `desciption` & `cost_cents`) to preview
   * @param {Object} options.extraParams - Extra parameters to send in the request
   */
  refetch: debounce(async function ({
    form, path, previewMilestones = [], extraParams = {},
  }) {
    const projectBreakdownContainer = form?.querySelector('[data-project-breakdown]');
    const projectBreakdownContentContainer = projectBreakdownContainer?.querySelector('[data-project-breakdown-content-container]');
    const milestoneSelect = this.getMilestoneSelect(projectBreakdownContainer);
    if (!path || !projectBreakdownContainer || !projectBreakdownContentContainer) return;

    try {
      // Always abort previous request if it exists
      if (this.fetchAbortController) {
        this.fetchAbortController.abort('fetch cancelled');
      }

      this.fetchAbortController = new AbortController();
      projectBreakdownContainer.setAttribute('loading', '');

      const response = await fetch(path, {
        method: 'POST',
        body: JSON.stringify({
          preview_milestones: previewMilestones,
          selected_milestone_identifier: !milestoneSelect?.hasAttribute('hidden') ? milestoneSelect?.value : null,
          ...extraParams,
        }),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          'X-CSRF-Token': getCSRFToken(),
        },
        signal: this.fetchAbortController.signal,
      });

      projectBreakdownContentContainer.innerHTML = await response.text();
    } catch (error) {
      // Ignore abort errors
      if (error.name !== 'AbortError') {
        console.warn(error);
      }
    } finally {
      projectBreakdownContainer?.removeAttribute('loading');
    }
  }, 200),
};
